 .title{
    text-align: center;
    color:whitesmoke;
    margin-top: 25px;
}


.dashIcon{
   margin-right: 12px; 
}

.logo{
    width: 30%;
    text-align: center;

}
.hover:hover{
    background-color:rgba(86, 19, 4, 0.274); 
    border-radius: 7px;
    
}
.active, .hover:hover{
    background-color: rgba(4, 86, 85, 0.839);
  /* color: white; */
}
div.w3-bar-item.title.hover{
    flex:1;
  /* background-color: rgb(40,100,250); */
  height: 5vh;
  transition: transform 0.3s linear;
  transform: translatex(4%);
}
.visible{
    transform: translatex(0);
    flex: 1;
  }
