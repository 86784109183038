html {
  /* background-color: black; */
}
body {
  /* background-color: black; */
}
.cards-wrapper {
  display: flex;
}
.card {
  margin: 0.5rem;
  /* width: calc(100% / 3); */
}
.image-wrapper {
  height: 50vw;
  /* display: flex;
    align-items: center;
    justify-content: center; */
  margin: 0 auto;
}
.image-wrapper img {
  max-width: 100%;
  max-height: 100%;
}
.res {
  width: 20%;
  height: 30%;
  border-radius: 5px;
  /* width: 100%; */
}
/* .border{
  border-radius: 30px;
} */
@media screen and(max-width:567px) {
  .card:not(:first-child) {
    display: none;
  }
}
.invalid{
  color: red;
}
