*{
    margin: 0;
    padding: 0;
}
.navbars{
    width: 100%;
    height: 8.6vh;
    background-color: #007782;
    position: fixed;
    display: flex;
    align-items: center;
    border-bottom:1px solid black;
    border-top: 1px solid black;
   /* border-radius: 5px; */
   z-index: 1;



}
img.logo{
    width: 3%;
    /* margin-left:px; */
    /* text-align: center; */
 
}
.titles{
 color:rgb(255, 255, 255);
 font-size: 25px;
 top: 17px;
 text-align: center;
 margin-left: 12px;
 /* margin-top: 10px; */
}
.logout{
width: 83%;
margin-left: 25px;
text-align: end;
color: whitesmoke;
cursor: pointer;

}

