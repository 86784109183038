.loginbody {
    background:url("../Images/kpop.jpg");
    height: 100vh;
    width: 100%;
    margin: auto;
    position: absolute;
    /* overflow: hidden; */
}

.card {
    border: none;
    height: 373px
}

.forms-inputs {
  position: relative;
  width: 100%;
}

.forms-inputs span {
  position: absolute;
  top: -18px;
  left: 10px;
  background-color: #fff;
  padding: 5px 10px;
  font-size: 15px;
}

.forms-inputs input {
    height: 50px;
    border: 1px solid #007782;
    width: 100%;
}

.forms-inputs input:focus {
  box-shadow: none;
  outline: none;
  border: 2px solid #000;
}

.btn {
  height: 50px;
}

.success-data {
  display: flex;
  flex-direction: column;
}

.bxs-badge-check {
  font-size: 90px;
}
.invalid {
  color: "red";
}
.formBody {
  position: absolute;
  top: 30%;
  left: 20%;
}
.outlet-value {
  position: absolute;
  left: 16%;
  width: 80%;
}
.outlet-value {
  position: absolute;
  left: 16%;
  width: 80%;
}
.form-data {
  align-items: center;
}
.totalAmount {
  margin-left: 50px !important;
}
.outlet-value{
    position: absolute;
   left: 16%;
    width: 80%;
    margin-top: 150px;
}
/* .title{
    font-size: large;
} */
.bgColor{
    background-color: #007782;
}
.errorMsg{
    color: red;
}
