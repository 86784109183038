.wholeSection {
  background-color: #1b1b1a;
}
.lightTheme {
  background-color: #fff;
}
.darkTheme {
  background-color: #1b1b1a;
}
.HeaderMain {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.HeaderMainLight {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.ItemImageExpand {
  position: relative;
}

.expand {
  float: left;
  position: relative;
  height: 100px;
  width: 100px;
  z-index: 1000;
  margin-bottom: -20px;
}

@media only screen and (max-width: 820px) {
  .imageSize {
    width: 60px !important;
    height: 50px !important;
    left: 0px !important;
    top: 5% !important;
    position: relative !important;
  }

  .headingText {
    width: 100% !important;
    display: flex !important;
    align-items: flex-start !important;
  }
  .headingTextLight {
    width: 100% !important;
    display: flex !important;
    align-items: flex-start !important;
  }

  .ItemDescription {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .containerImage {
    width: 100%;
    padding-top: 10px !important;
  }
  .ItemImageCss{
    height: 200px !important;
  }


  .CloseButton {
    position: absolute !important;
    top: 0px !important;
    right: 0px !important;
  }
  .itemName,
  .ItemPrice {
    font-weight: 30 !important;
    font-size: 18px !important;
    color: #ffffff;
  }

  .itemNameLight,
  .ItemPriceLight {
    font-weight: 30 !important;
    font-size: 18px !important;
    color: black !important;
  }

  .submenuItemName,
  .subMenuItemPrice {
    font-weight: 30 !important;
    font-size: 16px !important;
    color: #ffffff;
  }
  .submenuItemNameLight,
  .subMenuItemPriceLight {
    font-weight: 30 !important;
    font-size: 16px !important;
    color: black !important;
  }
  .subMenuImage {
    height: 50px !important;
    width: 50px !important;
    border-radius: 10px !important;
    margin-bottom: 10px;
    margin-left: 20px;
  }
  .subMenuName {
    padding-left: 5px !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    color: #ffffff !important;
    margin-top: 10px;
  }
  .subMenuprice {
    font-weight: 600 !important;
    font-size: 15px !important;
    color: #ffffff !important;
  }

  .subMenuNameLight {
    padding-left: 5px !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    color: black !important;
    margin-top: 10px;
    width: 140px !important;
  }
  .subMenupriceLight {
    font-weight: 600 !important;
    font-size: 15px !important;
    color: black !important;
  }

  .subMenuItemPrice {
    margin-top: 5px !important;
  }
  .footerMenu {
    width: 100% !important;
  }
  .ToogleSwitchStyle {
    margin-top: 20px !important;
    margin-right: 5px !important;
  }
}

@media only screen and (max-width: 768px) {
  .HeaderMain {
    width: 100%;
    height: 100px;
    background-color: #403e3c;
    border-end-end-radius: 5px;
  }
  .HeaderMainLight {
    width: 100%;
    height: 100px;
    background-color: white;
    border-end-end-radius: 5px;
  }

  .logoImage {
    height: 150px;
    width: 150px;
  }

  .ResturantName {
    font-size: 28px;
  }

  .toogleBar {
    padding-right: 20px;
  }
}

@media only screen and (max-width: 820px) {
  .HeaderMain {
    width: 100% !important;
  }

  .logoImage {
    height: 150px;
    width: 150px;
  }

  .ResturantName {
    font-size: 28px;
  }

  .toogleBar {
    padding-right: 5px;
  }
  .ResturantNameLightTheme {
    color: black;
  }
}

.logoImage {
  position: absolute;
  width: 80px;
  height: 80px;
  left: 23.72px;
  top: 12px;
}

.ResturantName {
  height: 20px;
  left: 100px;
  top: 15px;
  font-family: "David Libre";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 15px;
  padding-left: 120px;
  padding-top: 30px;
  color: white;
}
.ResturantNameLightTheme {
  height: 20px;
  left: 100px;
  top: 15px;
  font-family: "David Libre";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 15px;
  padding-left: 120px;
  padding-top: 30px;
  color: black;
}

.toogleBar {
  width: 50px;
  height: 30px;
  top: 55px;
  margin-right: 20px;
  margin-top: 30px;
}

.categoriesMain {
  height: 350px;
  /* background-color: #1b1b1a; */
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .categoriesMain {
    height: 250px;
    width: 100%;
  }

  .CategoriesItem1,
  .CategoriesItem4,
  .CategoriesItem3,
  .CategoriesItem2 {
    width: 20% !important;
    height: 180px !important;
    border-radius: 5px;
    margin-left: 15px;
    padding-right: 5px;
  }
  .imageSize {
    width: 150px;
    height: 100px;
    left: 94.06px;
    top: 20%;
    position: relative;
  }

  .CategoryName {
    font-weight: 50 !important;
    font-size: 16px !important;
    color: #e4e4e3;
    margin-top: 2rem;
    padding-left: 20px !important;
    width: 200px !important;
    margin-bottom: 40px !important;
  }

  .CategoryName3 {
    font-weight: 50 !important;
    font-size: 20px !important;
    color: #e4e4e3;
    padding-left: 20px !important;
    width: 200px !important;
    padding-left: 20px !important;
  }
}

@media screen and (max-width: 820px) {
  .categoriesMain {
    height: 200px;
    width: 100%;
  }

  .CategoriesItem1,
  .CategoriesItem4,
  .CategoriesItem3,
  .CategoriesItem2 {
    width: 18% !important;
    height: 152px !important;
    border-radius: 5px;
    margin-left: 15px;
    padding-right: 5px;
    margin-top: 25px !important;
  }
  .imageSize {
    width: 150px;
    height: 150px;
    left: 94.06px;
    top: 20%;
    position: relative;
  }

  .CategoryName {
    font-weight: 50 !important;
    font-size: 12px !important;
    color: #e4e4e3;
    /* border: 1px solid; */
    /* padding-left: 8px !important;
    width: 200px !important; */
    position: relative;
    left: -2.4rem;
  }

  .CategoryName3 {
    font-weight: 50 !important;
    font-size: 20px !important;
    color: #e4e4e3;
    padding-left: 20px !important;
    width: 200px !important;
    padding-left: 20px !important;
  }
}

.CategoriesItem1 {
  display: flex;
  /* background: #124c91; */
  width: 25%;
  height: 250px;
  flex-direction: column;
  margin-left: 2rem;
  /*
    left: 10px;
    top: 100px;
    background: #124C91;
    border-radius: 5px;
    margin-top: 45px;
    margin-left: 22px;
    padding-right: 10px; */
}

.CategoriesItem2 {
  width: 25%;
  height: 250px;
  left: 10px;
  top: 100px;
  background: #967318;
  border-radius: 5px;
  margin-top: 45px;
  margin-left: 22px;
}

.CategoriesItem3 {
  width: 25%;
  height: 250px;
  left: 10px;
  top: 100px;
  background: rgba(240, 5, 5, 0.38);
  border-radius: 5px;
  margin-top: 45px;
  margin-left: 22px;
}

.CategoriesItem4 {
  width: 25%;
  height: 250px;
  left: 10px;
  top: 100px;
  background: #394a07;
  border-radius: 5px;
  margin-top: 45px;
  margin-left: 22px;
  margin-right: 22px;
}

.imageSize {
  width: 350px;
  height: 150px;
  left: 98px;
  top: 20%;
  position: relative;
  border-radius: 0%;
}

.CategoryName,
.CategoryName3 {
  font-weight: 1000;
  font-size: 20px;
  letter-spacing: 0.05em;
  color: #e4e4e3;
  padding-left: 120px;
  margin-top: 2rem;
  width: 100px;
}

.categoryHeadingArea {
  height: 50px;
  left: 9px;
  background: #f1f1f0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* border-radius: 5px; */
}
.categoryHeadingArea {
  height: 50px;
  left: 9px;
  background: #2d3536;
  /* border-radius: 5px; */
}
.ColorOFscrollUp {
  right: 0;
  bottom: 0;
  position: fixed;
  font-size: 40px;
}

.headingText {
  font-family: "Courier New";
  font-weight: 600;
  font-size: 28px;
  margin-top: 0.1px;
  color: #ffffff;
  padding-top: 8px;
  padding-left: 22px;
}
.headingTextLight {
  font-family: "Courier New";
  font-weight: 600;
  font-size: 28px;
  margin-top: 0.1px;
  color: black;
  padding-top: 8px;
  padding-left: 22px;
}
.modalDesing {
  background-color: white;
}

@media screen and (max-width: 500px) {
  .categoryHeadingArea {
    height: 40px;
    padding-left: 0.2px;
    background: black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* border-radius: 5px; */
  }
  .owl-stage-outer .owl-stage .owl-item {
    padding-left: 0px;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 10px;
    height: 130px !important;
  }
  .categoryHeadingAreaLight {
    height: 40px;
    padding-left: 0.2px;
    background: #f1f1f0;
    /* border-radius: 5px; */
  }

  .headingText {
    font-family: "Courier New";
    font-weight: 600;
    font-size: 22px;
    margin-top: 0.1px;
    color: #ffffff;
    padding-top: 6px;
    padding-left: 22px;
  }
  .headingTextLight {
    font-family: "Courier New";
    font-weight: 600;
    font-size: 22px;
    margin-top: 0.1px;
    color: black;
    padding-top: 6px;
    padding-left: 22px;
  }

  .headingTextLight {
    font-family: "Courier New";
    font-weight: 600;
    font-size: 22px;
    margin-top: 0.1px;
    color: black;
    padding-top: 6px;
    padding-left: 22px;
  }
}

.ItemDescription {
  /* background-color: #424a40; */
  display: flex;
  padding: 0px;
}
.containerImage{
  display: flex;
  height: 250px;
}

/* @media screen and (max-width: 500px) {
  /* .ItemDescription {
    height: 1200px;
    background-color: #424a40;
    display: flex;
    flex-direction: column;
  } */

/* .Item1 {
    width: 100% !important;
    background-color: #313231;
  }

  .Item2 {
    width: 100% !important;
    background-color: #313231;
  }

  .itemImage {
    width: 100%;
    height: 500px !important;
  }

  .itemName,
  .ItemPrice,
  .submenuItemName,
  .subMenuItemPrice,
  .subMenuName,
  .subMenuprice {
    font-weight: 600;
    font-size: 18px !important;
    color: #ffffff;
    padding-left: 10px;
  }

  .lineMarker {
    width: 98%;
    height: 1px !important;
    background-color: #ffffff;
    margin-left: 12px;
    margin-right: 5px !important;
  }

  .subMenuImage {
    width: 60px !important;
    height: 60px !important;
    padding-left: 20px !important;
  }

  .imageAndName {
    display: flex;
  }

  .subMenuName {
    padding-left: 20px;
  }

  .subMenuprice {
    padding-right: 20px;
    padding-top: 10px;
  } */

.Item1 {
  width: 50%;
  /* background-color: #313231; */
}

.Item2 {
  width: 50%;
  /* background-color: #313231; */
}

.itemImage {

  width: 180px;
  height: 180px !important;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-right: 10px;
  border-radius: 10px;
}

.itemNamePrice {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.subMenuName{
  font-weight: 500 !important;
  margin-top: 40px !important;
  font-size: 17px !important;

}


.subMenuNameLight{
  font-weight: 500 !important;
  width: fit-content !important;
  margin-top: 10px !important;
  font-size: 17px !important;
  margin-right: 10px;
}
.subMenuprice{
  margin-left: 10px !important;
  height: 50px !important;
  width: 140px !important;

}
.subMenupriceLight{
  margin-left: 10px !important;
  height: 50px !important;
  width: 140px !important;
}

.itemName,
.ItemPrice,
.submenuItemName,
.subMenuItemPrice,
.subMenuName,
.subMenuprice {
  font-weight: 600;
  font-size: 25px;
  color: #ffffff;
}
.submenuItemName,
.subMenuItemPrice {
  font-weight: 600;
  font-size: 25px;
  color: black;
}

.lineMarker {
  width: 98%;
  height: 2px;
  background-color: #ffffff;
  margin-left: 0px;
}

.lineMarkerLight {
  width: 98%;
  height: 0.5px;
  background-color: #241d1c;
  margin-left: 0px;
}

.subMenuHeading {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.subMenuItems {
  /* /display: flex; */
  /* justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: -10px; */
}

.subMenuImage {
  width: 100px;
  height: 100px;
}

.imageAndName {
  display: flex;
}

.subMenuName {
  padding-left: 20px;
}

.subMenuprice {
  padding-right: 20px;
  padding-top: 10px;
}
.subMenupriceLight {
  padding-right: 20px;
  padding-top: 10px;
}

.FooterLogo {
  width: 50px;
  height: 50px;
}
.CloseButton{
  height: 30px;
  width: 30px;
}
@media only screen and (max-width: 820px) {
  .footerMenu {
    display: flex;
    justify-content: space-between;
    background-color: #403e3c;
    width: 100% !important;
    margin-top: 10px;
  }
  .footerMenuLight {
    display: flex;
    justify-content: space-between;
    background-color: white;
    width: 100% !important;
  }

  .FooterLogo {
    width: 25px !important;
    height: 25px !important;
    margin-top: 10px !important;
    margin-left: 10px !important;
  }

  .Timing {
    font-weight: 150;
    font-size: 12px !important;
    color: #ffffff;
    padding-right: 10px;
  }
  .TimingLight {
    font-weight: 150;
    font-size: 12px !important;
    color: black !important;
    padding-right: 10px;
  }

  .CloseButton2 {
    /* position: absolute;
      top: 5;
      right: 5; */
  }
}

.footerMenu {
  display: flex;
  justify-content: space-between;
  /* background-color: black; */
  /* height: 150px; */
}

.Timing {
  font-weight: 600;
  font-size: 25px;
  color: #ffffff;
  padding-top: 10px;
}

/* for the modal Css */

@media only screen and (max-width: 820px) {
  .ModalItemName {
    height: 400px !important;
    /* margin-top: 10px !important; */
    width: 90%;
  }
  .subMenuNameDescription {
    font-size: 12px;
    font-weight: 20;
    color: white;
    width: 455px !important;
    margin-left: 2px !important;
    padding-right: 10px;
    margin-left: 45px !important;
  }
  .subMenuNameDescriptionLight {
    font-size: 12px;
    font-weight: 20;
    color: black;
    width: 455px !important;
    margin-left: 2px !important;
    padding-right: 10px;
    margin-left: 45px !important;
  }
  .usedIngrediantHeading {
    margin-left: 45px !important;
  }

  .containerImage {
    position: relative !important;
  }

  .videofield {
    height: 400px !important;
    width: 400px !important;
    /* margin-left: 60px !important; */
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .Video {
    border-radius: 20px;
    margin-bottom: 40px !important;
    height: 400px !important;
  }
  .CloseButton2 {
    height: 50px;
    width: 50px;
    position: absolute !important;
    top: 5px !important;
    /* right: 70px !important; */
  }
  .subMenuNameModal {
    margin-left: 80px !important;
    font-size: 22px !important;
    color: #ffffff !important;
  }
  .subMenuNameModalLight {
    margin-left: 80px !important;
    font-size: 22px !important;
    color: black !important;
  }

  .subMenuNameModalItem {
    margin-left: 90px !important;
    font-size: 14px !important;
    color: #ffffff !important;
    font-weight: 100 !important;
  }
  .subMenuNameModalItemLight {
    margin-left: 90px !important;
    font-size: 14px !important;
    color: #ffffff !important;
    font-weight: 100 !important;
  }

  .subMenuNameDescriptionModal {
    margin-left: 90px !important;
    font-size: 12px !important;
    color: #ffffff !important;
    font-weight: 200 !important;
  }
  .subMenuNameModalItemLight {
    margin-left: 90px !important;
    font-size: 14px !important;
    color: black !important;
    font-weight: 100 !important;
  }
  .subMenuNameDescriptionModalLight {
    margin-left: 90px !important;
    font-size: 12px !important;
    color: black !important;
    font-weight: 200 !important;
  }
  .ItemImageCss{
    height: 200px !important;
  }
}

@media only screen and (max-width: 600px) {
  .categories .owl-carousel .owl-item {
  height: 128px !important;
}
  .ModalItemName {
    /* height: 400px !important; */
    /* margin-top: 10px !important; */
    width: 100% !important;
    height: 280px !important;
    padding: 50px !important;
  }
  .owl-stage-outer .owl-stage .owl-item {
    padding-left: 0px;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 10px;
    height: 130px !important;
  }
  .ItemImageCss{
    height: 200px !important;
  }
  .subMenuNameDescription {
    font-size: 12px;
    font-weight: 20;
    color: white;
    width: 100% !important;
    position: relative;
    /* margin-left: 2px !important; */
    padding:4px;
    /* margin-left: 60px !important; */
  }
  .subMenuNameDescriptionLight {
    font-size: 12px;
    font-weight: 20;
    color: black;
    width: 100% !important;
    position: relative;
    /* width: 455px !important; */
    /* margin-left: 2px !important;
    padding-right: 10px;
    margin-left: 45px !important;
    wid


  } */


}  .usedIngrediantHeading {
    margin-left: 45px !important;
  }

  .containerImage {
    position: relative !important;
    width: 100%;
  }

  .videofield {
    height: 400px !important;
    width: 100% !important;
    position: relative;
    padding-right: 30px !important;
    /* margin-left: 70px !important; */
    /* margin-top: 10px !important; */
    /* margin-bottom: 10px !important; */
  }
  .Video {
    border-radius: 20px;
    margin-bottom: 40px !important;
    height: 400px !important;
  }
  .CloseButton2 {
    height: 50px;
    width: 50px;
    position: absolute !important;
    top: 0px !important;
    /* right: 120px !important; */
  }
  .subMenuNameModal {
    /* margin-left: 120px !important; */
    font-size: 22px !important;
    color: #ffffff !important;
    margin-left: 50px !important;
    margin-top: 20px !important;
  }
  .subMenuNameModalLight {
    /* margin-left: 120px !important; */
    font-size: 22px !important;
    color: black !important;
    margin-left: 50px !important;
    margin-top: 20px !important;
  }

  .subMenuNameModalItem {
    margin-left: 50px !important;
    font-size: 14px !important;
    color: #ffffff !important;
    font-weight: 100 !important;
  }
  .subMenuNameDescriptionModal {
    margin-left: 50px !important;
    font-size: 12px !important;
    color: #ffffff !important;
    font-weight: 200 !important;
    padding-right: 50px !important;
    position: relative;


  }
  .subMenuNameModalItemLight {
    margin-left: 50px !important;
    font-size: 14px !important;
    color:black !important;
    font-weight: 100 !important;
  }
  .subMenuNameDescriptionModalLight {
    margin-left: 50px !important;
    font-size: 12px !important;
    color: black !important;
    font-weight: 200 !important;
    padding-right: 50px !important;
    position: relative;

  }
}

.CloseButton2 {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 0px;
  right: 0px;
}
.CloseButton2Light {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 0px;
  right: 0px;
  color: black;
  border-color: black;
  /* background-color:#1b1b1a; */
}

.ModalItemName {
  height: 500px;
  width: 600px;
  margin-top: 0px;
  display: block;
}
.subMenuNameDescription {
  font-size: 12px;
  font-weight: 20;
  color: white;
  padding-left: 20px;
  padding-right: 10px;
}

.subMenuNameDescriptionLight {
  font-size: 12px;
  font-weight: 20;
  color: black;
  padding-left: 20px;
  padding-right: 10px;
}

.usedIngrediantHeading {
  font-size: 18px;
  font-weight: 100;
  color: white;
  padding-left: 20px;
}

.usedIngrediantHeadingLight {
  font-size: 18px;
  font-weight: 100;
  color: black;
  padding-left: 20px;
}

.usedIngrediant {
  width: 100%;
  height: 100px;
}

.videofield {
  height: 500px;
  width: 500px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding-left: 50px;
}
.Video {
  border-radius: 20px;
  margin-bottom: 40px;
}
.containerImage {
  position: relative;
}

.CloseButton {
  position: absolute;
  top: 0;
  right: 0;
}

.expandMenu {
  position: relative;
}
.modalCss {
  /* background-color: black; */
  width: 600px;
  height: 100%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.modalStyle {
  padding-top: 20px;
  padding-bottom: 20px;
}

.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  bottom: 27rem;
  right: 2rem;
  font-size: 3rem;
  font-weight: 900;
}
.owl-stage {
  /* background-color: #1b1b1a; */
}
.owl-stage-outer .owl-stage .owl-item {
  padding-left: 0px;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.owl-stage-outer .owl-stage .owl-item:nth-child(even) {
  background-color: #124c91;
  height: 100%;
}
.owl-stage-outer .owl-stage .owl-item:nth-child(odd) {
  background-color: #967318;
  height: 100%;
}

.owl-stage-outer .owl-stage .owl-item:nth-child(3) {
  background-color: #f00505;
  height: 100%;
}
.owl-stage-outer .owl-stage .owl-item:nth-child(4) {
  background-color: #394a07;
  height: 100%;
}

.imageDescription {
  position: relative;
}
.imageDescription img {
  display: block;
}
.imageDescription .CloseButton1 {
  position: absolute;
  top: 0;
  right: 0;
}

.ReactModal__Overlay {
  z-index: 2;
}
a {
  text-decoration: none;
}
.CategoryName {
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.categories .owl-carousel .owl-stage-outer {
  height: 200px;
}

.owl-carousel .owl-stage-outer {
  height: 900px;
}
.owl-item {
  height: 100%;
}
.categories .owl-carousel .owl-item img {
  width: 80%;
  height: 100%;
}
.owl-carousel .owl-stage {
  height: 100%;
}
.owl-carousel .owl-item .card img {
  /* width: 70%; */
  height: 77%;
  object-fit: cover;
}
.owl-carousel.owl-loaded {
  margin-top: 2rem;
}